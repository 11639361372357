





























import { walletStore } from '@/stores/wallet-store'
import { autorun, IReactionDisposer } from 'mobx'
import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class ConnectWalletButton extends Vue {
  @PropSync('drawer') drawerDialog!: boolean

  wallet = walletStore

  status = ''
  account = ''

  disposes: IReactionDisposer[] = []

  mounted() {
    this.disposes = [
      autorun(() => {
        this.account = this.wallet.account

        if (this.wallet.isKycVerified) {
          this.status = 'Verified'
        } else if (this.wallet.isKycPending) {
          this.status = 'Pending'
        } else {
          this.status = 'Unverified'
        }
      }),
    ]
  }

  async copyAddress() {
    if (this.wallet.account) {
      await navigator.clipboard.writeText(this.wallet.account)
    }
  }

  beforeDestroy() {
    this.disposes.forEach((d) => d())
  }
}
